.sd-element {
  padding-left: var(--sv-element-add-padding-left, 0px);
  padding-right: var(--sv-element-add-padding-right, 0px);
}

.sd-element__title {
  outline: none;
}

.sd-element__title.sd-element__title--disabled {
  opacity: 0.25;
}

.sd-root--readonly .sd-element__title.sd-element__title--disabled {
  opacity: 1;
}

.sd-element--invisible {
  opacity: 0.35;
}

.sd-title.sd-element__title {
  font-family: $font-questiontitle-family;
  font-weight: $font-questiontitle-weight;
  color: $font-questiontitle-color;
}

.sd-element__header {
  .sv-string-editor {
    max-width: 100%;
    white-space: normal;
  }
}

.sd-element__title {
  font-size: 0;
  line-height: 0;
  position: static;
  margin: 0;

  .sd-element__num {
    font-size: calcFontSize(0.75);
    line-height: calcLineHeight(1);
    color: $foreground-light;
  }

  span {
    font-size: $font-questiontitle-size;
    line-height: multiply(1.5, $font-questiontitle-size);
  }

  .sv-title-actions__title {
    font-size: 0;
    line-height: 0;
  }

  .sv-title-actions__title {
    white-space: nowrap;
  }

  .sv-string-viewer {
    white-space: normal;
  }
}

.sd-element__num {
  float: left;
  padding-top: calcSize(0.625);
  padding-bottom: calcSize(0.375);
  padding-inline-start: 0;
  padding-inline-end: calcSize(1);
  width: calcSize(5);
  font-size: calcFontSize(0.75);
  line-height: calcLineHeight(1);
  color: $foreground-light;
  margin-inline-start: calcSize(-5); // margin-left
  text-align: end;
  box-sizing: border-box;
  white-space: nowrap;
  flex-shrink: 0;

  &+span {
    float: left;
    width: 0;
  }
}

[dir="rtl"],
[style*="direction:rtl"],
[style*="direction: rtl"] {
  .sd-element__num {
    float: right;
  }
}

.sd-element__title--num-inline {
  .sd-element__num {
    @include num_inline();
  }
}

.sd-element__title--expandable.sd-element__title--expandable {
  position: relative;
  display: block;
}

.sd-element__title--expandable:before {
  content: "";
  display: inline-block;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 14L5 13L10 8L5 3L6 2L12 8L6 14Z' fill='%23909090'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center center;
  height: calcSize(2);
  width: calcSize(2);
  position: absolute;
  left: calcSize(-3);
  top: calcSize(0.5);
}

.sd-element__title--expandable.sd-element__title--expanded:before {
  transform: rotate(90deg);
}

//with borders styles
.sd-element--with-frame {
  border-radius: $panel-corner-radius;
  box-sizing: border-box;
  padding-top: var(--sd-base-padding);
  padding-left: calc(var(--sd-base-padding) + var(--sv-element-add-padding-left, 0px));
  padding-right: calc(var(--sd-base-padding) + var(--sv-element-add-padding-right, 0px));
  padding-bottom: var(--sd-base-padding);
  background: $question-background;
  box-shadow: $shadow-small;
  display: flex;
  flex-direction: column;
}

//compact styles
.sd-element--with-frame.sd-element--compact {
  border: 1px solid $border;
  border-radius: $panel-corner-radius;
  box-shadow: none;
  background-color: transparent;
}