.sd-progress-buttons__image-button-left {
  display: none;
}

.sd-progress-buttons__image-button-right {
  display: none;
}

.sd-progress-buttons__image-button--hidden {
  visibility: hidden;
}

.sd-progress-buttons__page-description {
  display: none;
}

.sd-progress-buttons {
  padding: calcSize(4) calcSize(5) calcSize(2) calcSize(5);
  display: flex;
  flex-direction: column;
}

.sd-progress-buttons__list-container {
  display: flex;
  overflow: hidden;
}

.sd-progress-buttons__connector {
  display: none;
}

.sd-progress-buttons__list {
  display: inline-flex;
  flex-direction: row;
  flex-grow: 1;
  margin: 0 auto;
  padding: 0;

  li {
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    position: relative;
    flex-wrap: nowrap;
    text-align: center;
    flex-direction: column;

    &:not(:first-child)>.sd-progress-buttons__connector {
      display: block;
      content: "";
      width: 100%;
      height: calcSize(0.25);
      background-color: $navigation;
      position: absolute;
      bottom: calcSize(0.875);
      right: 50%;
    }

    &:after {
      display: flex;
      content: attr(data-page-number);
      width: calcSize(0);
      height: calcSize(0);
      margin: calcSize(0.5);
      border: calcSize(0.5) solid transparent;
      background-color: $navigation;
      border-radius: 50%;
      cursor: pointer;
      align-self: center;
      z-index: 1;
      font-size: calcFontSize(0.75);
      font-weight: 600;
      line-height: calcLineHeight(1);
      justify-content: center;
      color: $background-dim;
    }
  }

  .sd-progress-buttons__list-element--passed {
    &:not(:first-child)>.sd-progress-buttons__connector {
      background-color: $primary;
    }

    &:after {
      background-color: $primary;
    }
  }

  .sd-progress-buttons__list-element--current {
    &:not(:first-child)>.sd-progress-buttons__connector {
      background-color: $primary;
    }

    &:after {
      border-color: $primary;
      background-color: $primary-foreground;
      color: $primary;
      padding: calcSize(0.5);
      margin: 0;
    }
  }
}

.sd-progress-buttons__page-title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: calcFontSize(0.75);
  font-weight: 600;
  line-height: calcLineHeight(1);
  flex-grow: 1;
  text-align: center;
  padding: 0 calcSize(2);
  margin-bottom: calcSize(2);
}

.sd-progress-buttons__header {
  .sd-progress-buttons__page-title {
    margin-bottom: calcSize(1);
  }
}

.sd-progress-buttons__footer {
  .sd-progress-buttons__page-title {
    margin-top: calcSize(1);
    margin-bottom: 0;
    justify-content: flex-end;
    padding: 0;
    color: $foreground-dim-light;
  }
}

.sd-progress-buttons--bottom {
  padding: calcSize(2) calcSize(5) calcSize(4) calcSize(5);
  flex-direction: column-reverse;

  .sd-progress-buttons__list {
    li {
      flex-direction: column-reverse;

      &:not(:first-child)>.sd-progress-buttons__connector {
        top: calcSize(0.875);
      }
    }
  }

  .sd-progress-buttons__page-title {
    margin-top: calcSize(2);
    margin-bottom: 0;
  }

  .sd-progress-buttons__header {
    .sd-progress-buttons__page-title {
      margin-top: calcSize(1);
      margin-bottom: 0;
    }
  }

  .sd-progress-buttons__footer {
    .sd-progress-buttons__page-title {
      margin-top: 0;
      margin-bottom: calcSize(1);
    }
  }
}

.sd-progress-buttons--numbered {
  .sd-progress-buttons__list {
    li {
      &:not(:first-child)>.sd-progress-buttons__connector {
        bottom: calcSize(1.875);
      }

      &:after {
        width: calcSize(2);
        height: calcSize(2);
      }
    }
  }
}

.sd-root-modern--mobile,
.sd-progress-buttons--no-titles {
  .sd-progress-buttons__list {
    justify-content: space-between;
    width: 100%;

    li {
      flex-grow: 0;
    }
  }
}

.sd-progress-buttons--bottom.sd-progress-buttons--numbered {
  .sd-progress-buttons__list {
    li {
      &:not(:first-child)>.sd-progress-buttons__connector {
        top: calcSize(1.875);
      }
    }
  }
}

.sd-root-modern--mobile {
  .sd-progress-buttons {
    padding: calcSize(2) calcSize(3);
  }
}